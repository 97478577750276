import { EventTypeProps } from '../Event';
import {
  EventComment,
  EventPerson,
  EventTeam,
  EventTitle,
  Time,
} from '../Event.styled';

import { ELEMENT_IDS, MATCH_EVENTS } from '@ntb-sport/constants';
import { getEventTime } from '../getEventTime';

export const GoalEvent = ({
  event,
  text,
  connectedText,
  personLink,
  teamLink,
  showComments,
}: EventTypeProps) => (
  <>
    <EventTitle as="h3" data-element-id={ELEMENT_IDS.TITLE}>
      {Object.prototype.hasOwnProperty.call(event, 'time') && (
        <Time>{getEventTime(event)}</Time>
      )}
      {text}
      {event?.team && (
        <>
          {', '}
          {teamLink ? (
            <EventTeam>
              {teamLink({
                id: event?.team?.id,
                name: event?.team?.name,
                sportId: event?.sportId,
              })}
            </EventTeam>
          ) : (
            <EventTeam>{event?.team?.name}</EventTeam>
          )}

          {` (${event?.score})`}
        </>
      )}

      {event?.person && (
        <>
          {', '}
          {connectedText && `${connectedText} `}
          {personLink && !event?.person?.hiddenPersonInfo ? (
            <EventPerson>
              {personLink({
                id: event?.person?.id,
                name: event?.person?.name,
                sportId: event?.sportId,
              })}
            </EventPerson>
          ) : (
            <EventPerson>{event?.person?.name}</EventPerson>
          )}
        </>
      )}
      {event?.connectedEvent && (
        <>
          {event?.connectedEvent.matchEventTypeId === MATCH_EVENTS.ASSIST
            ? '. Målgivende fra'
            : '. Indirekte målgivende fra'}{' '}
          {personLink && !event?.person?.hiddenPersonInfo ? (
            <EventPerson>
              {personLink({
                id: event?.connectedEvent?.person?.id,
                name: event?.connectedEvent?.person?.name,
                sportId: event?.sportId,
              })}
            </EventPerson>
          ) : (
            <EventPerson>{event?.connectedEvent?.person?.name}</EventPerson>
          )}
          {event?.connectedEvent?.person2 && (
            <>
              {` og `}
              {personLink ? (
                <EventPerson>
                  {personLink({
                    id: event?.connectedEvent?.person2?.id,
                    name: event?.connectedEvent?.person2?.name,
                    sportId: event?.sportId,
                  })}
                </EventPerson>
              ) : (
                <EventPerson>
                  {event?.connectedEvent?.person2?.name}
                </EventPerson>
              )}
            </>
          )}
          {'.'}
        </>
      )}
    </EventTitle>

    {showComments && <EventComment>{event?.comment}</EventComment>}
  </>
);

export default GoalEvent;
